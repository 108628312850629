import {
  forwardRef,
  InputHTMLAttributes,
  MutableRefObject,
  useEffect,
  useRef,
} from "react";

import "../styles/components/customInput.css";

export interface ICustomInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  wrapperClassname?: string;
  autoWidth?: boolean;
}

export const CustomInput = forwardRef<HTMLInputElement, ICustomInput>(
  (
    {
      id,
      label,
      type,
      className,
      required,
      wrapperClassname,
      autoWidth,
      ...rest
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const finalRef: any = ref || innerRef;

    const autowidth = (ref: MutableRefObject<HTMLInputElement>) => {
      ref.current.style.width = rest.value.toString().length + "ch";
    };

    useEffect(() => {
      if (autoWidth && finalRef.current) {
        autowidth(finalRef);
      }
    }, [rest.value]);

    return (
      <div className={`custom-input-wrapper ${wrapperClassname || ""}`}>
        {label && (
          <label htmlFor={id} className="custom-input-label">
            {label} {required && <span className="text-red-600">*</span>}
          </label>
        )}
        <input
          id={id}
          type={type}
          className={`custom-input custom-input-type__${type} ${className || ""}`}
          required={required}
          ref={finalRef}
          {...rest}
        />
      </div>
    );
  },
);

CustomInput.displayName = "CustomInput";
